<template>
  <div>
    <v-card-title class="headline">
      Training Plan
    </v-card-title>

    <v-card v-if="plan">
      <v-card-title class="display-2">{{plan.name}}</v-card-title>
      <v-card-text class="mt-4">
        Weeks: {{ plan.duration }}
        • workouts/week: {{ plan.workouts_week }}
        • goal: {{ plan.goal }}
      </v-card-text>
    
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Week</th>
              <th v-for="(item, idx) in plan.weeks[0].workouts" :key="idx" class="text-left">Workout {{ idx+1}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, idx) in plan.weeks" :key="idx">
              <td>
                {{week.week}}
                <div v-if="week.is_rest" class="font-weight-bold">
                  Rest
                </div>
                <div v-if="week.is_peak" class="font-weight-bold">
                  Peak
                </div>

              </td>
              <td v-for="(item, idx) in week.workouts" :key="idx" class="text-left">
                <div class="text-muted">
                  {{ item.type || uppercase }}
                  • duration: {{ item.total_duration | duration(/*compact:*/true) }}
                </div>
                {{ item.name }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>      

    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      id: null,
      plan: null,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;

    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {

    async getData() {
      this.plan = (await adminService.getTrainingPlan(this.id)).data;
    },
  },
  computed: {
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

